@charset "UTF-8";

// ==================================================
//  Foundation Global
// ==================================================
@use "foundation/_global" as *;

// ==================================================
//  Heading
// ==================================================
.c-heading {
  // 01
  &-01 {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;

    @include mq(md, max) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    &__text {
    }

    &__title {
      display: flex;
      align-items: center;
      column-gap: 40px;

      @include mq(md, max) {
        flex-wrap: wrap;
        gap: 24px;
      }
    }

    &__titleJa {
      font-size: 40px;
      line-height: calc(64 / 40);
      font-weight: 500;
      margin: 0;

      @include mq(md, max) {
        font-size: 26px;
      }
    }

    &__titleEn {
      display: block;
      font-size: 20px;
      line-height: calc(32 / 20);
      color: #787e83;
      margin: 0;

      @include mq(md, max) {
        font-size: 13px;
        transform: translateY(2px);
      }
    }

    &__description {
      font-size: 18px;
      line-height: calc(40 / 18);
      margin: 23px 0 0;

      @include mq(md, max) {
        font-size: 15px;
        margin-top: 20px;
      }
    }

    &__button {
      width: 284px;
      padding-bottom: 14px;

      @include mq(md, max) {
        display: none;
      }

      .c-button-01 {
        padding-top: 14px;
        padding-bottom: 14px;
      }
    }
  }

  // 02
  &-02 {
    font-size: 36px;
    line-height: calc(52 / 36);
    font-weight: 500;
    margin: 0;

    @include mq(md, max) {
      font-size: 20px;
    }
  }
}
