@charset "UTF-8";

// ==================================================
//  Foundation Global
// ==================================================
@use "foundation/_global" as *;

// ==================================================
//  Headline
// ==================================================
.l-headline {
  // 01
  &-01 {
    display: block;
    width: 100%;

    &__inner {
      @include sectionInner;
      width: calc(100% - 40px);
      max-width: 1024px;
      margin: 80px auto 0;
      padding-bottom: 120px;

      @include mq(md, max) {
        margin-top: 60px;
        padding-bottom: 80px;
      }

      // Modifier
      &--image {
        display: flex;
        align-items: center;

        .l-headline-01 {
          &__text {
            width: calc(100% - 340px);
            padding-right: 42px;

            @include mq(md, max) {
              width: 100%;
              padding-right: 0;
            }
          }
        }
      }
    }

    &__text {
      @include sectionInner;
    }

    &__textTitle {
      font-size: 46px;
      line-height: calc(67 / 46);
      font-weight: 500;
      margin: 46px 0 0;
      padding-bottom: 42px;

      @include mq(md, max) {
        font-size: 26px;
        margin-top: 26px;
        padding-bottom: 32px;
      }
    }

    &__textSubtitle {
      font-size: 28px;
      line-height: calc(50 / 28);
      font-weight: 500;
      margin: 28px 0 0;
      color: #86721f;

      @include mq(md, max) {
        font-size: 18px;
        line-height: calc(28 / 18);
        margin-top: 18px;
      }
    }

    &__textDescription {
      font-size: 18px;
      line-height: calc(36 / 18);
      margin: 20px 0 0;

      @include mq(md, max) {
        font-size: 15px;
        line-height: calc(27 / 15);
      }

      small {
        font-size: 12px;
        line-height: calc(19 / 12);
      }

      sup {
        font-size: 14px;
        margin-right: 5px;

        @include mq(md, max) {
          font-size: 12px;
        }
      }
    }

    &__image {
      display: block;
      width: 340px;
      padding-bottom: 12px;

      @include mq(md, max) {
        width: 200px;
        margin: 23px auto 0;
        padding-bottom: 0;
      }

      & > img {
        display: block;
        width: 100%;
      }
    }
  }
}
