@charset "UTF-8";

// ==================================================
//  Foundation Global
// ==================================================
@use "foundation/_global" as *;

// ==================================================
//  About
// ==================================================
.p-about {
  overflow: hidden;

  // Hearing
  &-hearing {
    display: block;
    width: 100%;
    padding: 100px 0 120px;
    position: relative;
    overflow: hidden;

    @include mq(md, max) {
      padding: 70px 0 80px;
    }

    &::before {
      content: "";
      display: block;
      width: calc(100% * 1.289);
      height: 100%;
      border-radius: 0px 700px 700px 0px;
      background: #f3f0e3;
      position: absolute;
      z-index: -1;
      top: 50%;
      right: -252px;
      transform: translateY(-50%);

      @include mq(md, max) {
        width: calc(100% * 1.917);
        right: -187px;
        border-radius: 0px 370px 370px 0px;
      }
    }

    &__inner {
      display: block;
      width: 100%;
      max-width: 1024px;
      margin: 0 auto;

      @include mq(md, max) {
        padding: 0 20px;
      }
    }

    &__image {
      display: block;
      width: 100%;
      margin: 40px 0 0;

      @include mq(md, max) {
        margin-top: 30px;
      }
    }

    &__paragraph {
      font-size: 18px;
      line-height: calc(36 / 18);
      margin: 29px 0 0;

      @include mq(md, max) {
        font-size: 15px;
        line-height: calc(27 / 15);
        margin-top: 30px;
      }
    }

    &__list {
      @include sectionInner;
      margin: 60px 0 0;
      padding: 0;

      @include mq(md, max) {
        margin-top: 50px;
      }
    }

    &__listItem {
      @include sectionInner;
      padding: 46px 56px;
      border: 4px solid #d9d9d9;
      background: #fff;
      margin-top: 20px;

      @include mq(md, max) {
        padding: 24px 22px;
        margin-top: 12px;
      }
    }

    &__listItemTitle {
      font-size: 28px;
      line-height: calc(41 / 28);
      margin: 28px 0 0;
      color: #86721f;
      font-weight: 500;

      @include mq(md, max) {
        font-size: 18px;
        line-height: calc(26 / 18);
        margin-top: 18px;
      }
    }

    &__listItemContent {
      font-size: 18px;
      line-height: calc(36 / 18);
      margin: 18px 0 0;

      @include mq(md, max) {
        font-size: 14px;
        line-height: calc(23 / 14);
        margin-top: 10px;
      }

      ul {
        list-style: disc;
        padding-left: 1.2em;
        margin: 10px 0;
      }

      ol {
        list-style: decimal;
        padding-left: 1.5em;
        margin: 10px 0;
      }
    }
  }

  // Service
  &-service {
    display: block;
    width: 100%;
    padding: 111px 0 0;

    @include mq(md, max) {
      padding: 70px 0 0;
    }

    &__inner {
      display: block;
      width: 100%;
      max-width: 1024px;
      margin: 0 auto;

      @include mq(md, max) {
        padding: 0 20px;
      }
    }

    &__content {
      @include sectionInner;
      margin-top: 50px;
    }

    &__title {
      font-size: 28px;
      line-height: calc(44 / 28);
      font-weight: 500;
      color: #86721f;
      margin: 100px 0 0;

      @include mq(md, max) {
        font-size: 18px;
        line-height: calc(28 / 18);
        margin-top: 60px;
      }
    }

    &__image {
      display: block;
      width: 100%;
      border-radius: 30px;
      background-color: #f3efe3;
      padding: 36px;
      margin-top: 31px;

      @include mq(md, max) {
        padding: 20px 8px;
        margin-top: 20px;
        border-radius: 10px;
      }

      & > img {
        display: block;
        width: 100%;
        max-width: 848px;
        margin: 0 auto;
      }

      & + p {
        margin-top: 41px;

        @include mq(md, max) {
          margin-top: 30px;
        }
      }
    }

    &__paragraph {
      font-size: 18px;
      line-height: calc(36 / 18);
      margin: 20px 0 0;

      @include mq(md, max) {
        font-size: 15px;
        line-height: calc(27 / 15);
      }
    }

    &__button {
      display: block;
      width: 400px;
      margin: 90px auto 0;

      @include mq(md, max) {
        width: 300px;
        margin-top: 40px;
      }
    }
  }
}
