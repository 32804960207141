@charset "UTF-8";

// ==================================================
//  Foundation Global
// ==================================================
@use "foundation/_global" as *;

// ==================================================
//  Footer
// ==================================================
.l-footer {
  display: block;
  width: 100%;
  margin: 175px 0 0;

  @include mq(md, max) {
    margin-top: 80px;
  }

  &__contact {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;

    @include mq(md, max) {
      flex-direction: column;
      gap: 20px;
      padding: 0 20px;
    }
  }

  &__contactItem {
    display: block;
    width: calc(50% - 6px);
    border: 4px solid #cfd2d4;
    padding: 36px 60px 54px 48px;
    position: relative;
    background-color: #fff;
    transition: background-color $c-transition;
    text-decoration: none;

    @include hover {
      background-color: #f5f3ea;
    }

    @include mq(md, max) {
      width: 100%;
      padding: 26px 22px 32px;
    }

    &::before {
      @include mq(md, max) {
        content: "";
        display: block;
        width: 34px;
        height: auto;
        aspect-ratio: 1/1;
        background-color: #f0f0f0;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }

    &::after {
      content: "";
      display: block;
      color: #000;
      width: 15px;
      height: auto;
      aspect-ratio: 1/1;
      border: 3px solid #000;
      border-left: 0;
      border-bottom: 0;
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      right: 30px;
      transform: translateY(-50%) rotate(45deg);

      @include mq(md, max) {
        width: 8px;
        top: auto;
        right: 13px;
        bottom: 12px;
        transform: rotate(45deg);
        border-width: 2px;
      }
    }
  }

  &__contactItemTitle {
    font-size: 28px;
    line-height: calc(44 / 28);
    font-weight: 500;
    text-align: center;
    margin: 0;

    @include mq(md, max) {
      font-size: 20px;
    }
  }

  &__contactItemDescription {
    font-size: 15px;
    line-height: calc(27 / 15);
    margin: 15px 0 0;

    @include mq(md, max) {
      font-size: 14px;
      margin-top: 13px;
    }
  }

  &__inner {
    @include sectionInner;
    display: flex;
    justify-content: space-between;
    column-gap: 40px;
    max-width: 1024px;
    margin: 81px auto 0;
    padding-bottom: 75px;

    @include mq(md, max) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      column-gap: 0;
      padding: 0 20px;
      margin: 70px 0 0;
      padding-bottom: 30px;
    }
  }

  &__innerRight {
    @include mq(md, max) {
      display: block;
      width: 100%;
    }
  }

  &__innerLeft {
  }

  &__logo {
    display: block;
    width: 158px;
    height: auto;
    aspect-ratio: 1/1;
    @include hoverOpacity;

    @include mq(md, max) {
      width: 86px;
    }

    & > img {
      display: block;
      width: 100%;
    }
  }

  &__copyright {
    font-size: 14px;
    margin: 145px 0 0;

    @include mq(md, max) {
      margin-top: 59px;
    }
  }

  &__menu {
    display: flex;
    flex-wrap: wrap;
    column-gap: 80px;

    @include mq(md, max) {
      flex-direction: column;
      column-gap: 0;
      margin-top: 31px;
      border-top: 1px solid #d9d9d9;
    }
  }

  &__menuList {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__menuListItem {
    font-size: 16px;
    font-weight: 500;
    line-height: calc(38 / 16);

    @include mq(md, max) {
      font-size: 14px;
      border-bottom: 1px solid #d9d9d9;
    }
  }

  &__menuListItemInner {
    @include hover {
      text-decoration: underline;
    }

    @include mq(md, max) {
      display: block;
      padding: 5px 11px;
      position: relative;
    }

    &::after {
      @include mq(md, max) {
        content: "";
        display: block;
        color: #000;
        width: 8px;
        height: auto;
        aspect-ratio: 1/1;
        border: 2px solid #000;
        border-left: 0;
        border-bottom: 0;
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%) rotate(45deg);
      }
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 66px 0 0;

    @include mq(md, max) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
    }
  }

  &__social {
    display: flex;
    align-items: center;
    column-gap: 12px;

    @include mq(md, max) {
      justify-content: center;
      margin-top: 24px;
      column-gap: 14px;
    }

    .icon {
      font-size: 30px;
    }
  }

  &__linksSocialItem {
    display: block;
  }

  &__privacypolicy {
    margin: 20px 0 0 0;
    font-size: 14px;
    line-height: calc(14 / 14);
    @include hoverOpacity;

    @include mq(md, max) {
      margin-top: 0;
    }
  }
}
