@charset "UTF-8";

// ==================================================
//  Foundation Global
// ==================================================
@use "foundation/_global" as *;

// ==================================================
//  Wrapper
// ==================================================
.l-wrapper {
  @include mq {
    min-width: 1077px;
  }
}

// ==================================================
//  Main
// ==================================================
.l-main {
  &:not(.p-home) {
    margin-top: 130px;

    @include mq(md, max) {
      margin-top: 81px;
    }
  }

  &__inner {
  }
}
