@charset "UTF-8";

// ==================================================
//  Foundation Global
// ==================================================
@use "foundation/_global" as *;

// ==================================================
//  Contact
// ==================================================
.p-contact {
  overflow: hidden;

  // Form
  &-form {
    display: block;
    width: calc(100% - 40px);
    max-width: 1024px;
    margin: 0 auto;

    // 既存コード
    .body {
      gap: 20px;

      @include mq {
        padding-right: 100px;
      }
    }
    div.body {
      select {
        @include mq(md, max) {
          width: 100% !important;
        }
      }
    }
    input[type="submit"] {
      &:disabled {
        background-color: #ccc;
      }
    }
    p.suffix {
      white-space: nowrap;
    }
    p.label {
      height: auto !important;
    }

    .cf7_wrap {
      display: flex;
      align-items: center;

      @include mq(md, max) {
        gap: 15px;
      }
    }

    .cf7_acceptance {
      text-align: center;
      margin: 60px 0;

      @include mq(md, max) {
        margin: 40px 0;
      }

      .wpcf7-list-item {
        margin: 0;
      }
    }

    .cf7_text {
      &--short {
        max-width: 300px;

        @include mq(md, max) {
          max-width: 240px;
        }
      }
    }

    // WordPress:Contact Form 7
    .wpcf7 {
      &-form-control-wrap {
        width: 100%;
      }

      &-list-item {
        display: inline-block;
        margin-right: 25px;
      }

      &-text {
        width: 100%;
      }
    }
  }

  //  Complete
  &-complete {
    display: block;
    width: calc(100% - 40px);
    max-width: 1024px;
    margin: 0 auto;

    &__backLink {
      display: block;
      width: 300px;

      @include mq(md, max) {
        width: 260px;
      }
    }
  }
}
