@charset "UTF-8";

// ==================================================
//  Font - import
// ==================================================
// Inter, Noto Sans JP
// @import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Noto+Sans+JP:wght@100..900&display=swap");

// ==================================================
//  Font - font-face
// ==================================================
// // Inter - 18pt - 400
// @font-face {
//   font-family: "Inter";
//   font-style: normal;
//   font-weight: 400;
//   src: url(../../assets/vendor/google-fonts/Inter/static/Inter_18pt-Regular.ttf) format("truetype");
// }
// // Noto Sans JP - 400
// @font-face {
//   font-family: "Noto Sans JP";
//   font-style: normal;
//   font-weight: 400;
//   src: url(../../assets/vendor/google-fonts/Noto_Sans_JP/static/NotoSansJP-Regular.ttf) format("truetype");
// }

// ==================================================
//  Font
// ==================================================
body {
  font-family: "Inter", "Noto Sans JP", "Hiragino Sans", "Hiragino Kaku Gothic ProN", YuGothic, "Yu Gothic", Meiryo, Verdana, sans-serif;
  font-feature-settings: normal;
  letter-spacing: 0;
}
