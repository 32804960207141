body {
  font-family: "Inter", "Noto Sans JP", "Hiragino Sans", "Hiragino Kaku Gothic ProN", YuGothic, "Yu Gothic", Meiryo, Verdana, sans-serif;
  font-feature-settings: normal;
  letter-spacing: 0;
}

.l-footer {
  display: block;
  width: 100%;
  margin: 175px 0 0;
}
@media screen and (max-width: 767px) {
  .l-footer {
    margin-top: 80px;
  }
}
.l-footer__contact {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .l-footer__contact {
    flex-direction: column;
    gap: 20px;
    padding: 0 20px;
  }
}
.l-footer__contactItem {
  display: block;
  width: calc(50% - 6px);
  border: 4px solid #cfd2d4;
  padding: 36px 60px 54px 48px;
  position: relative;
  background-color: #fff;
  transition: background-color 0.2s;
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  .l-footer__contactItem:hover {
    background-color: #f5f3ea;
  }
}
@media screen and (max-width: 767px) {
  .l-footer__contactItem {
    width: 100%;
    padding: 26px 22px 32px;
  }
}
@media screen and (max-width: 767px) {
  .l-footer__contactItem::before {
    content: "";
    display: block;
    width: 34px;
    height: auto;
    aspect-ratio: 1/1;
    background-color: #f0f0f0;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
.l-footer__contactItem::after {
  content: "";
  display: block;
  color: #000;
  width: 15px;
  height: auto;
  aspect-ratio: 1/1;
  border: 3px solid #000;
  border-left: 0;
  border-bottom: 0;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%) rotate(45deg);
}
@media screen and (max-width: 767px) {
  .l-footer__contactItem::after {
    width: 8px;
    top: auto;
    right: 13px;
    bottom: 12px;
    transform: rotate(45deg);
    border-width: 2px;
  }
}
.l-footer__contactItemTitle {
  font-size: 28px;
  line-height: 1.5714285714;
  font-weight: 500;
  text-align: center;
  margin: 0;
}
@media screen and (max-width: 767px) {
  .l-footer__contactItemTitle {
    font-size: 20px;
  }
}
.l-footer__contactItemDescription {
  font-size: 15px;
  line-height: 1.8;
  margin: 15px 0 0;
}
@media screen and (max-width: 767px) {
  .l-footer__contactItemDescription {
    font-size: 14px;
    margin-top: 13px;
  }
}
.l-footer__inner {
  display: block;
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 40px;
  max-width: 1024px;
  margin: 81px auto 0;
  padding-bottom: 75px;
}
.l-footer__inner > :first-child {
  margin-top: 0;
}
.l-footer__inner > :last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .l-footer__inner {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    column-gap: 0;
    padding: 0 20px;
    margin: 70px 0 0;
    padding-bottom: 30px;
  }
}
@media screen and (max-width: 767px) {
  .l-footer__innerRight {
    display: block;
    width: 100%;
  }
}
.l-footer__logo {
  display: block;
  width: 158px;
  height: auto;
  aspect-ratio: 1/1;
}
@media screen and (min-width: 768px) {
  .l-footer__logo {
    transition: opacity 0.2s;
  }
}
@media screen and (min-width: 768px) {
  .l-footer__logo:hover {
    opacity: 0.6;
  }
}
@media screen and (max-width: 767px) {
  .l-footer__logo {
    width: 86px;
  }
}
.l-footer__logo > img {
  display: block;
  width: 100%;
}
.l-footer__copyright {
  font-size: 14px;
  margin: 145px 0 0;
}
@media screen and (max-width: 767px) {
  .l-footer__copyright {
    margin-top: 59px;
  }
}
.l-footer__menu {
  display: flex;
  flex-wrap: wrap;
  column-gap: 80px;
}
@media screen and (max-width: 767px) {
  .l-footer__menu {
    flex-direction: column;
    column-gap: 0;
    margin-top: 31px;
    border-top: 1px solid #d9d9d9;
  }
}
.l-footer__menuList {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
}
.l-footer__menuListItem {
  font-size: 16px;
  font-weight: 500;
  line-height: 2.375;
}
@media screen and (max-width: 767px) {
  .l-footer__menuListItem {
    font-size: 14px;
    border-bottom: 1px solid #d9d9d9;
  }
}
@media screen and (min-width: 768px) {
  .l-footer__menuListItemInner:hover {
    text-decoration: underline;
  }
}
@media screen and (max-width: 767px) {
  .l-footer__menuListItemInner {
    display: block;
    padding: 5px 11px;
    position: relative;
  }
}
@media screen and (max-width: 767px) {
  .l-footer__menuListItemInner::after {
    content: "";
    display: block;
    color: #000;
    width: 8px;
    height: auto;
    aspect-ratio: 1/1;
    border: 2px solid #000;
    border-left: 0;
    border-bottom: 0;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%) rotate(45deg);
  }
}
.l-footer__links {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 66px 0 0;
}
@media screen and (max-width: 767px) {
  .l-footer__links {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
}
.l-footer__social {
  display: flex;
  align-items: center;
  column-gap: 12px;
}
@media screen and (max-width: 767px) {
  .l-footer__social {
    justify-content: center;
    margin-top: 24px;
    column-gap: 14px;
  }
}
.l-footer__social .icon {
  font-size: 30px;
}
.l-footer__linksSocialItem {
  display: block;
}
.l-footer__privacypolicy {
  margin: 20px 0 0 0;
  font-size: 14px;
  line-height: 1;
}
@media screen and (min-width: 768px) {
  .l-footer__privacypolicy {
    transition: opacity 0.2s;
  }
}
@media screen and (min-width: 768px) {
  .l-footer__privacypolicy:hover {
    opacity: 0.6;
  }
}
@media screen and (max-width: 767px) {
  .l-footer__privacypolicy {
    margin-top: 0;
  }
}

.l-header #logo,
.l-header .contact,
.l-header #menu,
.l-header #button-menu {
  display: none !important;
}
@media screen and (min-width: 768px) {
  .l-header:has(+ .p-home) .l-header__inner {
    border: none;
  }
}
@media screen and (min-width: 768px) {
  .l-header__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 19px 16px 21px;
    width: 100%;
    min-width: 1077px;
    position: fixed;
    top: 0;
    left: 0;
    border-bottom: 1px solid #d9d9d9;
    background-color: #fff;
  }
}
@media screen and (max-width: 767px) {
  .l-header__navigation {
    display: block;
    width: 100%;
    height: 100%;
    padding: 70px 20px 0;
    overflow: auto;
    position: fixed;
    z-index: 90;
    top: 0;
    left: 0;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0.2s;
  }
}
@media screen and (max-width: 767px) {
  .l-header__navigation.is-open {
    opacity: 1;
    visibility: visible;
  }
}
@media screen and (min-width: 768px) {
  .l-header__logo {
    display: block;
    width: 130px;
    height: auto;
  }
}
@media screen and (max-width: 767px) {
  .l-header__logo {
    width: 95px;
    position: fixed;
    top: 12px;
    left: 20px;
    z-index: 95;
  }
}
@media screen and (min-width: 768px) {
  .l-header__logo a,
.l-header__logo img {
    display: block;
    width: 100%;
  }
}
.l-header__menu {
  display: block;
  padding-right: 24px;
}
@media screen and (max-width: 767px) {
  .l-header__menu {
    padding: 0;
  }
}
.l-header__menuList {
  display: flex;
  column-gap: 1.875rem;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 767px) {
  .l-header__menuList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-top: 1px solid #d9d9d9;
  }
}
.l-header__menuListItem {
  font-size: 15px;
  line-height: 1.8;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .l-header__menuListItem {
    display: block;
    width: 100%;
    font-size: 14px;
    border-bottom: 1px solid #d9d9d9;
  }
}
.l-header__menuListItemInner {
  text-decoration: none;
}
@media screen and (max-width: 767px) {
  .l-header__menuListItemInner {
    display: block;
    padding: 8px 10px 10px;
    position: relative;
  }
}
@media screen and (min-width: 768px) {
  .l-header__menuListItemInner {
    transition: opacity 0.2s;
  }
}
@media screen and (min-width: 768px) {
  .l-header__menuListItemInner:hover {
    opacity: 0.6;
  }
}
@media screen and (max-width: 767px) {
  .l-header__menuListItemInner::after {
    content: "";
    display: block;
    color: #000;
    width: 8px;
    height: auto;
    aspect-ratio: 1/1;
    border: 2px solid #000;
    border-left: 0;
    border-bottom: 0;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    right: 9px;
    transform: translateY(-50%) rotate(45deg);
  }
}
.l-header__social {
  display: none;
}
@media screen and (max-width: 767px) {
  .l-header__social {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    column-gap: 14px;
  }
}
@media screen and (max-width: 767px) {
  .l-header__social .icon {
    font-size: 30px;
  }
}
.l-header__linksSocialItem {
  display: none;
}
@media screen and (max-width: 767px) {
  .l-header__linksSocialItem {
    display: block;
  }
}
.l-header__menuButton {
  display: none;
}
@media screen and (max-width: 767px) {
  .l-header__menuButton {
    display: block;
    width: 30px;
    height: 26px;
    position: fixed;
    z-index: 90;
    top: 21px;
    right: 20px;
    border-radius: 0;
    background: none;
  }
}
@media screen and (max-width: 767px) {
  .l-header__menuButton.is-open img:first-of-type {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .l-header__menuButton.is-open img:last-of-type {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .l-header__menuButton img:first-of-type {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .l-header__menuButton img:last-of-type {
    display: none;
  }
}

.l-headline-01 {
  display: block;
  width: 100%;
}
.l-headline-01__inner {
  display: block;
  width: 100%;
  width: calc(100% - 40px);
  max-width: 1024px;
  margin: 80px auto 0;
  padding-bottom: 120px;
}
.l-headline-01__inner > :first-child {
  margin-top: 0;
}
.l-headline-01__inner > :last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .l-headline-01__inner {
    margin-top: 60px;
    padding-bottom: 80px;
  }
}
.l-headline-01__inner--image {
  display: flex;
  align-items: center;
}
.l-headline-01__inner--image .l-headline-01__text {
  width: calc(100% - 340px);
  padding-right: 42px;
}
@media screen and (max-width: 767px) {
  .l-headline-01__inner--image .l-headline-01__text {
    width: 100%;
    padding-right: 0;
  }
}
.l-headline-01__text {
  display: block;
  width: 100%;
}
.l-headline-01__text > :first-child {
  margin-top: 0;
}
.l-headline-01__text > :last-child {
  margin-bottom: 0;
}
.l-headline-01__textTitle {
  font-size: 46px;
  line-height: 1.4565217391;
  font-weight: 500;
  margin: 46px 0 0;
  padding-bottom: 42px;
}
@media screen and (max-width: 767px) {
  .l-headline-01__textTitle {
    font-size: 26px;
    margin-top: 26px;
    padding-bottom: 32px;
  }
}
.l-headline-01__textSubtitle {
  font-size: 28px;
  line-height: 1.7857142857;
  font-weight: 500;
  margin: 28px 0 0;
  color: #86721f;
}
@media screen and (max-width: 767px) {
  .l-headline-01__textSubtitle {
    font-size: 18px;
    line-height: 1.5555555556;
    margin-top: 18px;
  }
}
.l-headline-01__textDescription {
  font-size: 18px;
  line-height: 2;
  margin: 20px 0 0;
}
@media screen and (max-width: 767px) {
  .l-headline-01__textDescription {
    font-size: 15px;
    line-height: 1.8;
  }
}
.l-headline-01__textDescription small {
  font-size: 12px;
  line-height: 1.5833333333;
}
.l-headline-01__textDescription sup {
  font-size: 14px;
  margin-right: 5px;
}
@media screen and (max-width: 767px) {
  .l-headline-01__textDescription sup {
    font-size: 12px;
  }
}
.l-headline-01__image {
  display: block;
  width: 340px;
  padding-bottom: 12px;
}
@media screen and (max-width: 767px) {
  .l-headline-01__image {
    width: 200px;
    margin: 23px auto 0;
    padding-bottom: 0;
  }
}
.l-headline-01__image > img {
  display: block;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .l-wrapper {
    min-width: 1077px;
  }
}

.l-main:not(.p-home) {
  margin-top: 130px;
}
@media screen and (max-width: 767px) {
  .l-main:not(.p-home) {
    margin-top: 81px;
  }
}
.c-breadcrumbs {
  display: block;
  width: calc(100% - 40px);
  max-width: 1024px;
  margin: 0 auto;
  overflow: auto;
}
.c-breadcrumbs::-webkit-scrollbar {
  display: none;
}
.c-breadcrumbs__inner {
  display: block;
  margin-left: auto;
  font-size: 16px;
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .c-breadcrumbs__inner {
    font-size: 12px;
  }
}
.c-breadcrumbs__inner * {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  white-space: nowrap;
}
.c-breadcrumbs__inner a,
.c-breadcrumbs__inner span {
  display: inline-block;
}
.c-breadcrumbs__inner a {
  color: inherit;
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  .c-breadcrumbs__inner a {
    transition: opacity 0.2s;
  }
}
@media screen and (min-width: 768px) {
  .c-breadcrumbs__inner a:hover {
    opacity: 0.6;
  }
}
.c-breadcrumbs__inner a::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 9px;
  height: auto;
  aspect-ratio: 1/1;
  border: 1px solid #000;
  border-left: 0;
  border-bottom: 0;
  box-sizing: border-box;
  transform: translateY(-1px) rotate(45deg);
  transition: border-color 0.2s;
  margin: 0 10px;
}

.c-button-ctaFixed {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  position: fixed;
  z-index: 75;
  right: 20px;
  bottom: 40px;
  transform: translateX(calc(100% + 20px));
  transition: transform 0.4s;
}
@media screen and (max-width: 767px) {
  .c-button-ctaFixed {
    row-gap: 4px;
    right: 6px;
    bottom: 12px;
    transform: translateX(calc(100% + 6px));
  }
}
.c-button-ctaFixed.is-show {
  transform: translateX(0);
}
.c-button-ctaFixed__button {
  display: block;
  width: 120px;
}
@media screen and (min-width: 768px) {
  .c-button-ctaFixed__button {
    transition: opacity 0.2s;
  }
}
@media screen and (min-width: 768px) {
  .c-button-ctaFixed__button:hover {
    opacity: 0.6;
  }
}
@media screen and (max-width: 767px) {
  .c-button-ctaFixed__button {
    width: 80px;
  }
}
.c-button-ctaFixed__button img {
  display: block;
  width: 100%;
}
.c-button-01 {
  display: block;
  width: 100%;
  font-size: 18px;
  line-height: 1.7777777778;
  padding: 8px 12px 9px 8px;
  font-weight: 500;
  border: 2px solid #000;
  background-color: #fff;
  position: relative;
  text-align: center;
  transition: background-color 0.2s, color 0.2s;
}
@media screen and (max-width: 767px) {
  .c-button-01 {
    font-size: 15px;
    padding: 11px;
  }
}
.c-button-01::after {
  content: "";
  display: block;
  width: 8px;
  height: auto;
  aspect-ratio: 1/1;
  border: 2px solid #000;
  border-left: 0;
  border-bottom: 0;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  right: 19px;
  transform: translateY(-50%) rotate(45deg);
  transition: border-color 0.2s;
}
@media screen and (min-width: 768px) {
  .c-button-01:hover {
    color: #fff;
    background-color: #000;
  }
  .c-button-01:hover::after {
    border-color: #fff;
  }
}
.c-button-02 {
  display: block;
  width: 100%;
  font-size: 18px;
  line-height: 1.7777777778;
  padding: 13px 12px 14px 8px;
  font-weight: 500;
  color: #fff;
  border: 2px solid #000;
  background-color: #000;
  position: relative;
  text-align: center;
  transition: background-color 0.2s, color 0.2s;
}
@media screen and (max-width: 767px) {
  .c-button-02 {
    font-size: 15px;
    padding: 11px;
  }
}
.c-button-02::after {
  content: "";
  display: block;
  width: 8px;
  height: auto;
  aspect-ratio: 1/1;
  border: 2px solid #fff;
  border-left: 0;
  border-bottom: 0;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  right: 19px;
  transform: translateY(-50%) rotate(45deg);
  transition: border-color 0.2s;
}
@media screen and (min-width: 768px) {
  .c-button-02:hover {
    color: #000;
    background-color: #fff;
  }
  .c-button-02:hover::after {
    border-color: #000;
  }
}

.c-heading-01 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .c-heading-01 {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}
.c-heading-01__title {
  display: flex;
  align-items: center;
  column-gap: 40px;
}
@media screen and (max-width: 767px) {
  .c-heading-01__title {
    flex-wrap: wrap;
    gap: 24px;
  }
}
.c-heading-01__titleJa {
  font-size: 40px;
  line-height: 1.6;
  font-weight: 500;
  margin: 0;
}
@media screen and (max-width: 767px) {
  .c-heading-01__titleJa {
    font-size: 26px;
  }
}
.c-heading-01__titleEn {
  display: block;
  font-size: 20px;
  line-height: 1.6;
  color: #787e83;
  margin: 0;
}
@media screen and (max-width: 767px) {
  .c-heading-01__titleEn {
    font-size: 13px;
    transform: translateY(2px);
  }
}
.c-heading-01__description {
  font-size: 18px;
  line-height: 2.2222222222;
  margin: 23px 0 0;
}
@media screen and (max-width: 767px) {
  .c-heading-01__description {
    font-size: 15px;
    margin-top: 20px;
  }
}
.c-heading-01__button {
  width: 284px;
  padding-bottom: 14px;
}
@media screen and (max-width: 767px) {
  .c-heading-01__button {
    display: none;
  }
}
.c-heading-01__button .c-button-01 {
  padding-top: 14px;
  padding-bottom: 14px;
}
.c-heading-02 {
  font-size: 36px;
  line-height: 1.4444444444;
  font-weight: 500;
  margin: 0;
}
@media screen and (max-width: 767px) {
  .c-heading-02 {
    font-size: 20px;
  }
}

.p-about {
  overflow: hidden;
}
.p-about-hearing {
  display: block;
  width: 100%;
  padding: 100px 0 120px;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .p-about-hearing {
    padding: 70px 0 80px;
  }
}
.p-about-hearing::before {
  content: "";
  display: block;
  width: 128.9%;
  height: 100%;
  border-radius: 0px 700px 700px 0px;
  background: #f3f0e3;
  position: absolute;
  z-index: -1;
  top: 50%;
  right: -252px;
  transform: translateY(-50%);
}
@media screen and (max-width: 767px) {
  .p-about-hearing::before {
    width: 191.7%;
    right: -187px;
    border-radius: 0px 370px 370px 0px;
  }
}
.p-about-hearing__inner {
  display: block;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .p-about-hearing__inner {
    padding: 0 20px;
  }
}
.p-about-hearing__image {
  display: block;
  width: 100%;
  margin: 40px 0 0;
}
@media screen and (max-width: 767px) {
  .p-about-hearing__image {
    margin-top: 30px;
  }
}
.p-about-hearing__paragraph {
  font-size: 18px;
  line-height: 2;
  margin: 29px 0 0;
}
@media screen and (max-width: 767px) {
  .p-about-hearing__paragraph {
    font-size: 15px;
    line-height: 1.8;
    margin-top: 30px;
  }
}
.p-about-hearing__list {
  display: block;
  width: 100%;
  margin: 60px 0 0;
  padding: 0;
}
.p-about-hearing__list > :first-child {
  margin-top: 0;
}
.p-about-hearing__list > :last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .p-about-hearing__list {
    margin-top: 50px;
  }
}
.p-about-hearing__listItem {
  display: block;
  width: 100%;
  padding: 46px 56px;
  border: 4px solid #d9d9d9;
  background: #fff;
  margin-top: 20px;
}
.p-about-hearing__listItem > :first-child {
  margin-top: 0;
}
.p-about-hearing__listItem > :last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .p-about-hearing__listItem {
    padding: 24px 22px;
    margin-top: 12px;
  }
}
.p-about-hearing__listItemTitle {
  font-size: 28px;
  line-height: 1.4642857143;
  margin: 28px 0 0;
  color: #86721f;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .p-about-hearing__listItemTitle {
    font-size: 18px;
    line-height: 1.4444444444;
    margin-top: 18px;
  }
}
.p-about-hearing__listItemContent {
  font-size: 18px;
  line-height: 2;
  margin: 18px 0 0;
}
@media screen and (max-width: 767px) {
  .p-about-hearing__listItemContent {
    font-size: 14px;
    line-height: 1.6428571429;
    margin-top: 10px;
  }
}
.p-about-hearing__listItemContent ul {
  list-style: disc;
  padding-left: 1.2em;
  margin: 10px 0;
}
.p-about-hearing__listItemContent ol {
  list-style: decimal;
  padding-left: 1.5em;
  margin: 10px 0;
}
.p-about-service {
  display: block;
  width: 100%;
  padding: 111px 0 0;
}
@media screen and (max-width: 767px) {
  .p-about-service {
    padding: 70px 0 0;
  }
}
.p-about-service__inner {
  display: block;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .p-about-service__inner {
    padding: 0 20px;
  }
}
.p-about-service__content {
  display: block;
  width: 100%;
  margin-top: 50px;
}
.p-about-service__content > :first-child {
  margin-top: 0;
}
.p-about-service__content > :last-child {
  margin-bottom: 0;
}
.p-about-service__title {
  font-size: 28px;
  line-height: 1.5714285714;
  font-weight: 500;
  color: #86721f;
  margin: 100px 0 0;
}
@media screen and (max-width: 767px) {
  .p-about-service__title {
    font-size: 18px;
    line-height: 1.5555555556;
    margin-top: 60px;
  }
}
.p-about-service__image {
  display: block;
  width: 100%;
  border-radius: 30px;
  background-color: #f3efe3;
  padding: 36px;
  margin-top: 31px;
}
@media screen and (max-width: 767px) {
  .p-about-service__image {
    padding: 20px 8px;
    margin-top: 20px;
    border-radius: 10px;
  }
}
.p-about-service__image > img {
  display: block;
  width: 100%;
  max-width: 848px;
  margin: 0 auto;
}
.p-about-service__image + p {
  margin-top: 41px;
}
@media screen and (max-width: 767px) {
  .p-about-service__image + p {
    margin-top: 30px;
  }
}
.p-about-service__paragraph {
  font-size: 18px;
  line-height: 2;
  margin: 20px 0 0;
}
@media screen and (max-width: 767px) {
  .p-about-service__paragraph {
    font-size: 15px;
    line-height: 1.8;
  }
}
.p-about-service__button {
  display: block;
  width: 400px;
  margin: 90px auto 0;
}
@media screen and (max-width: 767px) {
  .p-about-service__button {
    width: 300px;
    margin-top: 40px;
  }
}

.p-contact {
  overflow: hidden;
}
.p-contact-form {
  display: block;
  width: calc(100% - 40px);
  max-width: 1024px;
  margin: 0 auto;
}
.p-contact-form .body {
  gap: 20px;
}
@media screen and (min-width: 768px) {
  .p-contact-form .body {
    padding-right: 100px;
  }
}
@media screen and (max-width: 767px) {
  .p-contact-form div.body select {
    width: 100% !important;
  }
}
.p-contact-form input[type=submit]:disabled {
  background-color: #ccc;
}
.p-contact-form p.suffix {
  white-space: nowrap;
}
.p-contact-form p.label {
  height: auto !important;
}
.p-contact-form .cf7_wrap {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .p-contact-form .cf7_wrap {
    gap: 15px;
  }
}
.p-contact-form .cf7_acceptance {
  text-align: center;
  margin: 60px 0;
}
@media screen and (max-width: 767px) {
  .p-contact-form .cf7_acceptance {
    margin: 40px 0;
  }
}
.p-contact-form .cf7_acceptance .wpcf7-list-item {
  margin: 0;
}
.p-contact-form .cf7_text--short {
  max-width: 300px;
}
@media screen and (max-width: 767px) {
  .p-contact-form .cf7_text--short {
    max-width: 240px;
  }
}
.p-contact-form .wpcf7-form-control-wrap {
  width: 100%;
}
.p-contact-form .wpcf7-list-item {
  display: inline-block;
  margin-right: 25px;
}
.p-contact-form .wpcf7-text {
  width: 100%;
}
.p-contact-complete {
  display: block;
  width: calc(100% - 40px);
  max-width: 1024px;
  margin: 0 auto;
}
.p-contact-complete__backLink {
  display: block;
  width: 300px;
}
@media screen and (max-width: 767px) {
  .p-contact-complete__backLink {
    width: 260px;
  }
}

.p-design {
  overflow: hidden;
}
.p-design-whats {
  display: block;
  width: 100%;
  padding: 100px 0 120px;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .p-design-whats {
    padding: 70px 0 80px;
  }
}
.p-design-whats::before {
  content: "";
  display: block;
  width: 128.9%;
  height: 100%;
  border-radius: 0px 700px 700px 0px;
  background: #f3f0e3;
  position: absolute;
  z-index: -1;
  top: 50%;
  right: -252px;
  transform: translateY(-50%);
}
@media screen and (max-width: 767px) {
  .p-design-whats::before {
    width: 191.7%;
    right: -187px;
    border-radius: 0px 370px 370px 0px;
  }
}
.p-design-whats__inner {
  display: block;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}
.p-design-whats__inner > :first-child {
  margin-top: 0;
}
.p-design-whats__inner > :last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .p-design-whats__inner {
    padding: 0 20px;
  }
}
.p-design-whats__item {
  display: flex;
  align-items: flex-start;
  margin-top: 98px;
}
@media screen and (max-width: 767px) {
  .p-design-whats__item {
    flex-direction: column;
    margin-top: 50px;
  }
}
.p-design-whats__itemText {
  display: block;
  width: calc(100% - 355px);
  padding-right: 60px;
}
@media screen and (max-width: 767px) {
  .p-design-whats__itemText {
    width: 100%;
    padding-right: 0;
  }
}
.p-design-whats__itemTextTitle {
  padding-bottom: 22px;
}
@media screen and (max-width: 767px) {
  .p-design-whats__itemTextTitle {
    padding-bottom: 0;
  }
}
.p-design-whats__itemTextParagraph {
  font-size: 18px;
  line-height: 2;
  margin: 18px 0 0;
}
@media screen and (max-width: 767px) {
  .p-design-whats__itemTextParagraph {
    font-size: 15px;
    line-height: 1.8;
    margin-top: 20px;
  }
}
.p-design-whats__itemImage {
  display: block;
  width: 355px;
}
@media screen and (max-width: 767px) {
  .p-design-whats__itemImage {
    width: 197px;
    margin: 20px auto 0;
  }
}
.p-design-whats__itemImage > img {
  display: block;
  width: 100%;
}
.p-design-follow {
  display: block;
  width: 100%;
  padding: 120px 0 0;
}
@media screen and (max-width: 767px) {
  .p-design-follow {
    padding: 70px 0 0;
  }
}
.p-design-follow__inner {
  display: block;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .p-design-follow__inner {
    padding: 0 20px;
  }
}
.p-design-follow__content {
  display: block;
  width: 100%;
  margin-top: 40px;
}
.p-design-follow__content > :first-child {
  margin-top: 0;
}
.p-design-follow__content > :last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .p-design-follow__content {
    margin-top: 30px;
  }
}
.p-design-follow__paragraph {
  font-size: 18px;
  line-height: 2;
  margin: 20px 0 0;
}
@media screen and (max-width: 767px) {
  .p-design-follow__paragraph {
    font-size: 15px;
    line-height: 1.8;
  }
}
.p-design-follow__button {
  display: block;
  width: 400px;
  margin: 90px auto 0;
}
@media screen and (max-width: 767px) {
  .p-design-follow__button {
    width: 300px;
    margin-top: 40px;
  }
}

.p-flow {
  overflow: hidden;
}
.p-flow-schedule {
  display: block;
  width: 100%;
  padding: 100px 0 120px;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .p-flow-schedule {
    padding: 70px 0 80px;
  }
}
.p-flow-schedule::before {
  content: "";
  display: block;
  width: 128.9%;
  height: 100%;
  border-radius: 0px 700px 700px 0px;
  background: #f3f0e3;
  position: absolute;
  z-index: -1;
  top: 50%;
  right: -252px;
  transform: translateY(-50%);
}
@media screen and (max-width: 767px) {
  .p-flow-schedule::before {
    width: 191.7%;
    right: -187px;
    border-radius: 0px 370px 370px 0px;
  }
}
.p-flow-schedule__inner {
  display: block;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}
.p-flow-schedule__inner > :first-child {
  margin-top: 0;
}
.p-flow-schedule__inner > :last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .p-flow-schedule__inner {
    padding: 0 20px;
  }
}
.p-flow-schedule__content {
  display: block;
  width: 100%;
  position: relative;
  margin-top: 76px;
}
.p-flow-schedule__content > :first-child {
  margin-top: 0;
}
.p-flow-schedule__content > :last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .p-flow-schedule__content {
    margin-top: 102px;
  }
}
.p-flow-schedule__list {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  counter-reset: number;
  position: relative;
  z-index: 5;
}
.p-flow-schedule__listItem {
  display: block;
  font-size: 20px;
  line-height: 1.8;
  font-weight: 500;
  background-color: #fff;
  padding: 6px 74px;
  margin: 0;
  position: relative;
}
@media screen and (max-width: 767px) {
  .p-flow-schedule__listItem {
    font-size: 13px;
    padding: 5px 37px 3px;
  }
}
.p-flow-schedule__listItem::before {
  counter-increment: number;
  content: counter(number);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 25px;
  line-height: 1;
  color: #fff;
  font-weight: 500;
  background-color: #86721f;
}
@media screen and (max-width: 767px) {
  .p-flow-schedule__listItem::before {
    width: 30px;
    font-size: 16px;
  }
}
.p-flow-schedule__listItemNotice {
  display: inline;
  vertical-align: middle;
  font-size: 16px;
  line-height: 1.75;
}
@media screen and (max-width: 767px) {
  .p-flow-schedule__listItemNotice {
    font-size: 11px;
  }
}
.p-flow-schedule__note {
  font-size: 14px;
  line-height: 1.8571428571;
  margin: 21px 0 0;
}
@media screen and (max-width: 767px) {
  .p-flow-schedule__note {
    font-size: 12px;
    margin-top: 12px;
  }
}
.p-flow-schedule__image {
  display: block;
  width: 386px;
  position: absolute;
  top: -150px;
  right: 0;
}
@media screen and (max-width: 767px) {
  .p-flow-schedule__image {
    width: 210px;
    top: -81px;
  }
}
.p-flow-schedule__label {
  display: block;
  height: auto;
  position: absolute;
  z-index: 10;
  width: 150px;
  top: 9px;
}
@media screen and (max-width: 767px) {
  .p-flow-schedule__label {
    width: 37px;
    top: 8px;
  }
}
.p-flow-schedule__label--tochi {
  right: 201px;
}
@media screen and (max-width: 767px) {
  .p-flow-schedule__label--tochi {
    right: 53px;
  }
}
.p-flow-schedule__label--shikin {
  right: 41px;
}
@media screen and (max-width: 767px) {
  .p-flow-schedule__label--shikin {
    right: 10px;
  }
}
.p-flow-support {
  display: block;
  width: 100%;
  padding: 120px 0 0;
}
@media screen and (max-width: 767px) {
  .p-flow-support {
    padding: 70px 0 0;
  }
}
.p-flow-support__inner {
  display: block;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}
.p-flow-support__inner > :first-child {
  margin-top: 0;
}
.p-flow-support__inner > :last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .p-flow-support__inner {
    padding: 0 20px;
  }
}
.p-flow-support__content {
  display: block;
  width: 100%;
  margin-top: 55px;
}
.p-flow-support__content > :first-child {
  margin-top: 0;
}
.p-flow-support__content > :last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .p-flow-support__content {
    margin-top: 40px;
  }
}
.p-flow-support__item {
  display: block;
  width: 100%;
  border: 4px solid #d9d9d9;
  background-color: #fff;
  padding: 46px 56px;
  margin-top: 20px;
  position: relative;
}
.p-flow-support__item > :first-child {
  margin-top: 0;
}
.p-flow-support__item > :last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .p-flow-support__item {
    border-width: 2px;
    padding: 24px 22px;
  }
}
.p-flow-support__item:not(:last-of-type)::before {
  content: "";
  display: block;
  width: 7px;
  height: 22px;
  background-color: #ab9e65;
  position: absolute;
  z-index: -1;
  bottom: -24px;
  left: 50%;
  transform: translateX(-50%);
}
.p-flow-support__itemTitle {
  font-size: 28px;
  line-height: 1.5714285714;
  margin: 28px 0 0;
  font-weight: 500;
  color: #86721f;
}
@media screen and (max-width: 767px) {
  .p-flow-support__itemTitle {
    font-size: 18px;
    line-height: 1.4444444444;
    margin-top: 18px;
  }
}
.p-flow-support__itemInner {
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 767px) {
  .p-flow-support__itemInner {
    flex-direction: column-reverse;
  }
}
@media screen and (min-width: 768px) {
  .p-flow-support__itemInner--image .p-flow-support__itemText {
    width: calc(100% - 392px);
    padding-right: 25px;
  }
}
.p-flow-support__itemText {
  display: block;
  width: 100%;
  margin: 18px 0 0;
}
.p-flow-support__itemText > :first-child {
  margin-top: 0;
}
.p-flow-support__itemText > :last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .p-flow-support__itemText {
    margin-top: 10px;
  }
}
.p-flow-support__itemTextParagraph {
  font-size: 18px;
  line-height: 2;
  margin: 16px 0 0;
}
@media screen and (max-width: 767px) {
  .p-flow-support__itemTextParagraph {
    font-size: 14px;
    line-height: 1.6428571429;
    margin-top: 10px;
  }
}
.p-flow-support__itemImage {
  display: block;
  width: 392px;
}
@media screen and (max-width: 767px) {
  .p-flow-support__itemImage {
    width: 100%;
    margin-top: 10px;
  }
}
.p-flow-support__itemImage img {
  display: block;
  width: 100%;
}

.p-home {
  overflow: hidden;
}
.p-home-mv {
  display: block;
  width: 100%;
  position: relative;
  padding: 145px 0 0;
}
@media screen and (max-width: 767px) {
  .p-home-mv {
    padding: 90px 0 0;
  }
}
.p-home-mv.is-active .p-home-mv__sliderListItemText {
  transform: translateY(0);
  opacity: 1;
}
.p-home-mv::before {
  content: "";
  display: block;
  width: 100%;
  height: 123px;
  background-color: #f3f0e3;
  position: absolute;
  bottom: -1px;
  left: 0;
}
@media screen and (max-width: 767px) {
  .p-home-mv::before {
    height: 93px;
  }
}
.p-home-mv__sliderListItem {
  position: relative;
}
.p-home-mv__sliderListItemInner {
  display: block;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .p-home-mv__sliderListItemInner {
    transition: opacity 0.2s;
  }
}
@media screen and (min-width: 768px) {
  .p-home-mv__sliderListItemInner:hover {
    opacity: 0.6;
  }
}
.p-home-mv__sliderListItemText {
  display: block;
  position: absolute;
  top: 0;
  right: -16px;
  transform: translateY(-15px);
  opacity: 0;
  transition: transform 0.4s, opacity 0.4s;
  width: 73px;
}
@media screen and (max-width: 767px) {
  .p-home-mv__sliderListItemText {
    width: 44px;
    top: 10px;
    right: -8px;
  }
}
.p-home-mv__sliderListItemText--3line {
  width: 113px;
  right: -56px;
}
@media screen and (max-width: 767px) {
  .p-home-mv__sliderListItemText--3line {
    width: 67px;
    right: -32px;
  }
}
.p-home-news {
  display: block;
  width: 100%;
  margin: 0;
  padding: 99px 0 146px;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .p-home-news {
    padding: 49px 0 100px;
  }
}
.p-home-news::before {
  content: "";
  display: block;
  width: 128.9%;
  height: 100%;
  border-radius: 0 0 0 700px;
  background: #f3f0e3;
  position: absolute;
  z-index: -1;
  top: 50%;
  right: -252px;
  transform: translateY(-50%);
}
@media screen and (max-width: 767px) {
  .p-home-news::before {
    width: 191.7%;
    right: -127px;
    border-radius: 0px 0 0 370px;
  }
}
.p-home-news__inner {
  display: block;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .p-home-news__inner {
    padding: 0 20px;
  }
}
.p-home-news__openhouse {
  display: block;
  width: 100%;
  margin: 40px 0 0;
}
@media screen and (max-width: 767px) {
  .p-home-news__openhouse {
    margin-top: 30px;
  }
}
.p-home-news__openhouseInner {
  display: flex;
  align-items: center;
  column-gap: 40px;
  background-color: #fff;
  position: relative;
  padding: 12px;
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  .p-home-news__openhouseInner {
    transition: opacity 0.2s;
  }
}
@media screen and (min-width: 768px) {
  .p-home-news__openhouseInner:hover {
    opacity: 0.6;
  }
}
@media screen and (max-width: 767px) {
  .p-home-news__openhouseInner {
    flex-direction: column;
    column-gap: 0;
    padding: 10px 10px 0;
  }
}
.p-home-news__openhouseInner::after {
  content: "";
  display: block;
  color: #000;
  width: 15px;
  height: auto;
  aspect-ratio: 1/1;
  border: 3px solid #000;
  border-left: 0;
  border-bottom: 0;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  right: 34px;
  transform: translateY(-50%) rotate(45deg);
}
@media screen and (max-width: 767px) {
  .p-home-news__openhouseInner::after {
    display: none;
  }
}
.p-home-news__openhouseImage {
  display: block;
  width: 390px;
  height: auto;
  aspect-ratio: 1/0.667;
}
@media screen and (max-width: 767px) {
  .p-home-news__openhouseImage {
    width: 100%;
  }
}
.p-home-news__openhouseImage img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.p-home-news__openhouseText {
  display: block;
  width: 488px;
}
@media screen and (max-width: 767px) {
  .p-home-news__openhouseText {
    width: 100%;
    margin-top: 16px;
    padding: 0 16px;
  }
}
.p-home-news__openhouseTextNote {
  display: block;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .p-home-news__openhouseTextNote {
    font-size: 13px;
  }
}
.p-home-news__openhouseTextTitle {
  font-size: 34px;
  line-height: 1.5;
  font-weight: 500;
  margin: 9px 0 0;
}
@media screen and (max-width: 767px) {
  .p-home-news__openhouseTextTitle {
    font-size: 20px;
    margin-top: 12px;
  }
}
.p-home-news__openhouseTextInformation {
  font-size: 18px;
  line-height: 1.6666666667;
  margin: 9px 0 0;
}
@media screen and (max-width: 767px) {
  .p-home-news__openhouseTextInformation {
    font-size: 14px;
    margin-top: 12px;
  }
}
.p-home-news__openhouseSpButton {
  display: none;
}
@media screen and (max-width: 767px) {
  .p-home-news__openhouseSpButton {
    display: block;
    width: calc(100% + 20px);
    font-size: 15px;
    line-height: 1.8;
    font-weight: 500;
    text-align: center;
    color: #fff;
    background-color: #787e83;
    padding: 6px 8px 9px;
    margin-top: 20px;
    position: relative;
  }
}
@media screen and (max-width: 767px) {
  .p-home-news__openhouseSpButton::after {
    content: "";
    display: block;
    color: #fff;
    width: 8px;
    height: auto;
    aspect-ratio: 1/1;
    border: 2px solid #fff;
    border-left: 0;
    border-bottom: 0;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translateY(-50%) rotate(45deg);
  }
}
.p-home-news__list {
  display: flex;
  flex-wrap: wrap;
  gap: 50px 26px;
  width: 100%;
  margin: 80px 0 0;
}
@media screen and (max-width: 767px) {
  .p-home-news__list {
    flex-direction: column;
    margin-top: 60px;
    gap: 30px 0;
    padding: 0 25px;
  }
}
.p-home-news__listItem {
  display: block;
  width: calc(33.3333333333% - 17.3333333333px);
}
@media screen and (max-width: 767px) {
  .p-home-news__listItem {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .p-home-news__listItem:nth-child(n+4) {
    display: none;
  }
}
.p-home-news__listItemInner {
  display: block;
  position: relative;
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  .p-home-news__listItemInner:hover .p-home-news__listItemImage img {
    transform: scale(1.1);
  }
  .p-home-news__listItemInner:hover .p-home-news__listItemText {
    text-decoration: underline;
  }
}
.p-home-news__listItemLabel {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  line-height: 1.4375;
  font-weight: 500;
  padding: 4px 12px 3px;
  background-color: #fff;
}
@media screen and (max-width: 767px) {
  .p-home-news__listItemLabel {
    font-size: 15px;
    padding: 2px 12px;
  }
}
.p-home-news__listItemImage {
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 1/0.667;
  border: 4px solid #fff;
  overflow: hidden;
}
.p-home-news__listItemImage img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1);
  transition: transform 0.5s ease;
}
.p-home-news__listItemText {
  display: block;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-home-news__listItemText {
    padding: 0 3px;
  }
}
.p-home-news__listItemTextTitle {
  font-size: 18px;
  line-height: 1.5555555556;
  margin: 10px 0 0;
}
@media screen and (max-width: 767px) {
  .p-home-news__listItemTextTitle {
    font-size: 15px;
    margin-top: 6px;
  }
}
.p-home-news__listItemTextDate {
  display: block;
  font-size: 16px;
  line-height: 1.1875;
  margin: 5px 0 0;
}
@media screen and (max-width: 767px) {
  .p-home-news__listItemTextDate {
    font-size: 13px;
    margin-top: 4px;
  }
}
.p-home-news__button {
  display: block;
  width: 284px;
  margin: 56px auto 0;
}
@media screen and (min-width: 768px) {
  .p-home-news__button {
    transform: translateX(20px);
  }
}
@media screen and (max-width: 767px) {
  .p-home-news__button {
    width: 280px;
    margin-top: 40px;
  }
}
@media screen and (min-width: 768px) {
  .p-home-news__button .c-button-01 {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}
.p-home-future {
  display: block;
  width: 100%;
  margin: 0;
  padding: 90px 0 100px;
}
@media screen and (max-width: 767px) {
  .p-home-future {
    padding: 70px 0 80px;
  }
}
.p-home-future__inner {
  display: flex;
  justify-content: space-between;
  max-width: 1024px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .p-home-future__inner {
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
  }
}
.p-home-future__innerLeft {
  display: block;
  padding: 8px 0 0 80px;
}
@media screen and (max-width: 767px) {
  .p-home-future__innerLeft {
    padding: 0 10px;
  }
}
.p-home-future__innerRight {
  display: block;
  width: 640px;
  padding: 56px 0 0 0;
}
@media screen and (max-width: 767px) {
  .p-home-future__innerRight {
    width: 100%;
    padding: 0 10px;
  }
}
.p-home-future__title {
  font-size: 37px;
  font-weight: 500;
}
@media screen and (min-width: 768px) {
  .p-home-future__title {
    line-height: 1.7567567568;
    writing-mode: vertical-rl;
    letter-spacing: 0.16em;
  }
}
@media screen and (max-width: 767px) {
  .p-home-future__title {
    font-size: 24px;
    line-height: 1.7916666667;
  }
}
.p-home-future__text {
  display: block;
  width: 100%;
}
.p-home-future__text > :first-child {
  margin-top: 0;
}
.p-home-future__text > :last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .p-home-future__text {
    margin-top: 30px;
  }
}
.p-home-future__text p {
  font-size: 18px;
  line-height: 2.2222222222;
  margin: 18px 0 0;
}
@media screen and (max-width: 767px) {
  .p-home-future__text p {
    font-size: 15px;
    line-height: 1.8;
    margin-top: 15px;
  }
}
.p-home-future__button {
  display: block;
  width: 284px;
  margin: 50px 0 0;
}
@media screen and (max-width: 767px) {
  .p-home-future__button {
    width: 280px;
    margin: 40px auto 0;
  }
}
@media screen and (min-width: 768px) {
  .p-home-future__button .c-button-01 {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}
.p-home-works {
  display: block;
  width: 100%;
  padding: 117px 0 124px;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .p-home-works {
    padding: 80px 0 100px;
  }
}
.p-home-works::before {
  content: "";
  display: block;
  width: 128.9%;
  height: 100%;
  border-radius: 0px 700px 700px 0px;
  background: #eff1f2;
  position: absolute;
  z-index: -1;
  top: 50%;
  right: -252px;
  transform: translateY(-50%);
}
@media screen and (max-width: 767px) {
  .p-home-works::before {
    width: 191.7%;
    right: -187px;
    border-radius: 0px 370px 370px 0px;
  }
}
.p-home-works__inner {
  display: block;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .p-home-works__inner {
    padding: 0 20px;
  }
}
.p-home-works__list {
  display: flex;
  flex-wrap: wrap;
  gap: 109px 32px;
  list-style: none;
  margin: 60px 0 0;
  padding: 0;
}
@media screen and (max-width: 767px) {
  .p-home-works__list {
    flex-direction: column;
    gap: 30px 0;
    margin-top: 30px;
    padding: 0 25px;
  }
}
.p-home-works__listItem {
  display: block;
  width: calc(33.3333333333% - 21.3333333333px);
}
@media screen and (max-width: 767px) {
  .p-home-works__listItem {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .p-home-works__listItem:nth-child(n+4) {
    display: none;
  }
}
.p-home-works__listItemInner {
  display: block;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .p-home-works__listItemInner {
    transition: opacity 0.2s;
  }
}
@media screen and (min-width: 768px) {
  .p-home-works__listItemInner:hover {
    opacity: 0.6;
  }
}
.p-home-works__listItemImage {
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
}
.p-home-works__listItemImage > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.p-home-works__listItemTitle {
  display: block;
  width: 100%;
  margin: 30px 0 0;
  font-size: 28px;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .p-home-works__listItemTitle {
    font-size: 20px;
    margin-top: 14px;
  }
}
.p-home-works__listItemText {
  display: block;
  width: 100%;
  margin: 22px 0 0;
}
@media screen and (max-width: 767px) {
  .p-home-works__listItemText {
    margin-top: 8px;
  }
}
.p-home-works__listItemText > p {
  font-size: 18px;
  line-height: 1.6111111111;
  margin: 0;
}
@media screen and (max-width: 767px) {
  .p-home-works__listItemText > p {
    font-size: 14px;
  }
}
.p-home-works__button {
  width: 300px;
  margin: 80px auto 0;
}
@media screen and (max-width: 767px) {
  .p-home-works__button {
    width: 280px;
    margin-top: 40px;
  }
}
@media screen and (min-width: 768px) {
  .p-home-works__button .c-button-01 {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}
.p-home-instagram {
  display: block;
  width: 100%;
  padding: 130px 0 0;
  position: relative;
}
@media screen and (max-width: 767px) {
  .p-home-instagram {
    padding: 70px 20px 0;
  }
}
.p-home-instagram::before {
  content: "";
  display: block;
  width: 448px;
  height: auto;
  aspect-ratio: 1/0.971;
  background-color: #f3f0e3;
  border-radius: 0 700px 700px 0;
  position: absolute;
  top: 270px;
  left: -145px;
}
@media screen and (max-width: 767px) {
  .p-home-instagram::before {
    display: none;
  }
}
.p-home-instagram::after {
  content: "";
  display: block;
  width: 728px;
  height: auto;
  aspect-ratio: 1/0.97;
  background-color: #f3f0e3;
  border-radius: 700px 0 0 700px;
  position: absolute;
  top: 344px;
  right: -346px;
}
@media screen and (max-width: 767px) {
  .p-home-instagram::after {
    width: 255px;
    top: auto;
    right: -132px;
    bottom: -245px;
  }
}
.p-home-instagram__inner {
  display: block;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  position: relative;
  z-index: 5;
}
.p-home-instagram__feed {
  display: block;
  width: 100%;
  margin: 50px 0 0;
}
@media screen and (max-width: 767px) {
  .p-home-instagram__feed {
    margin-top: 30px;
  }
}
.p-home-instagram__feed #sbi_images > div {
  height: auto;
  aspect-ratio: 1/1;
}
.p-home-instagram__feed #sbi_images > div .sbi_photo_wrap,
.p-home-instagram__feed #sbi_images > div .sbi_photo {
  height: 100% !important;
}
@media screen and (max-width: 767px) {
  .p-home-instagram__button {
    width: 280px;
    margin: 40px auto 0;
  }
}
.p-home-about {
  display: block;
  width: 100%;
  padding: 177px 0 0;
}
@media screen and (max-width: 767px) {
  .p-home-about {
    padding: 83px 0 0;
  }
}
.p-home-about__inner {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .p-home-about__inner {
    flex-direction: column;
    gap: 60px;
    padding: 0 20px;
  }
}
.p-home-about__item {
  display: block;
  width: calc(50% - 12px);
}
@media screen and (max-width: 767px) {
  .p-home-about__item {
    width: 100%;
  }
}
.p-home-about__title {
  font-size: 28px;
  line-height: 1.5714285714;
  font-weight: 500;
  margin: 0;
}
@media screen and (max-width: 767px) {
  .p-home-about__title {
    font-size: 20px;
  }
}
.p-home-about__image {
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 1/0.64;
  margin: 20px 0 0;
}
@media screen and (max-width: 767px) {
  .p-home-about__image {
    margin-top: 16pxs;
  }
}
.p-home-about__image > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.p-home-about__paragraph {
  font-size: 18px;
  line-height: 1.7777777778;
  margin: 22px 0 0;
  padding-right: 10px;
}
@media screen and (max-width: 767px) {
  .p-home-about__paragraph {
    font-size: 14px;
    line-height: 1.7857142857;
    margin-top: 10px;
    padding-right: 0;
  }
}
.p-home-about__button {
  display: block;
  width: 228px;
  margin: 22px auto 0;
}
@media screen and (max-width: 767px) {
  .p-home-about__button {
    width: 280px;
    margin-top: 30px;
  }
}

@media screen and (min-width: 768px) {
  .u-hidden--pc {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .u-hidden--sp {
    display: none;
  }
}