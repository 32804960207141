@charset "UTF-8";

// ==================================================
//  Foundation Variables, Function
// ==================================================
@use "foundation/_variables" as *;
@use "foundation/_function" as *;

// ==================================================
//  Media Query
//
//  ▼Sample
//  @include mq {} = @media screen and (min-width: 768px)
//  @include mq(lg) {} = @media screen and (min-width: 1024px)
//  @include mq(lg, max) {} = @media screen and (max-width: 1023px)
//  @include mq(md, max) {} = @media screen and (max-width: 767px)
// ==================================================
$mq-breakpoints: (
  "xs": 321px,
  "sm": 576px,
  "md": 768px,
  "lg": 1024px,
  "xl": 1280px,
  "xxl": 1920px,
) !default;
@mixin mq($breakpoint: md, $rule: min) {
  $breakpoint: map-get($mq-breakpoints, $breakpoint);
  @if $rule == max {
    $breakpoint: $breakpoint - 1px;
  }
  @media screen and (#{$rule}-width: $breakpoint) {
    @content;
  }
}

// ==================================================
//  Hover - PC
// ==================================================
@mixin hover {
  @include mq {
    &:hover {
      @content;
    }
  }
}

// ==================================================
//  Hover Opacity - PC
// ==================================================
@mixin hoverOpacity {
  @include mq {
    transition: opacity $c-transition;
  }

  @include hover {
    opacity: $c-opacity;
  }
}

// ==================================================
//  Section Inner
// ==================================================
@mixin sectionInner {
  display: block;
  width: 100%;

  & > :first-child {
    margin-top: 0;
  }
  & > :last-child {
    margin-bottom: 0;
  }
}

// ==================================================
//  Clearfix
// ==================================================
@mixin clearfix {
  &::after {
    content: "";
    clear: both;
    display: block;
  }
}

// ==================================================
//  Fade In Up Before / After
// ==================================================
@mixin fadeInUpBefore {
  opacity: 0;
  visibility: hidden;
  transform: translateY(rem(15));
  transition: opacity $c-transition, visibility $c-transition, transform $c-transition;
}
@mixin fadeInUpAfter {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
