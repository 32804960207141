@charset "UTF-8";

// ==================================================
//  Foundation Global
// ==================================================
@use "foundation/_global" as *;

// ==================================================
//  Hidden
// ==================================================
.u-hidden {
  // Modifier
  &--pc {
    @include mq {
      display: none;
    }
  }
  &--sp {
    @include mq(md, max) {
      display: none;
    }
  }
}
