@charset "UTF-8";

// ==================================================
//  Foundation Global
// ==================================================
@use "foundation/_global" as *;

// ==================================================
//  Home
// ==================================================
.p-home {
  overflow: hidden;

  // Mv
  &-mv {
    display: block;
    width: 100%;
    position: relative;
    padding: 145px 0 0;

    @include mq(md, max) {
      padding: 90px 0 0;
    }

    &.is-active {
      .p-home-mv {
        &__sliderListItemText {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 123px;
      background-color: #f3f0e3;
      position: absolute;
      bottom: -1px;
      left: 0;

      @include mq(md, max) {
        height: 93px;
      }
    }

    &__slider {
    }

    &__sliderList {
    }

    &__sliderListItem {
      position: relative;
    }

    &__sliderListItemInner {
      display: block;
      width: 100%;
      @include hoverOpacity;
    }

    &__sliderListItemImage {
    }

    &__sliderListItemText {
      display: block;
      position: absolute;
      top: 0;
      right: -16px;
      transform: translateY(-15px);
      opacity: 0;
      transition: transform 0.4s, opacity 0.4s;
      width: 73px;

      @include mq(md, max) {
        width: 44px;
        top: 10px;
        right: -8px;
      }

      // Modifier
      &--3line {
        width: 113px;
        right: -56px;

        @include mq(md, max) {
          width: 67px;
          right: -32px;
        }
      }
    }
  }

  // News
  &-news {
    display: block;
    width: 100%;
    margin: 0;
    padding: 99px 0 146px;
    position: relative;
    overflow: hidden;

    @include mq(md, max) {
      padding: 49px 0 100px;
    }

    &::before {
      content: "";
      display: block;
      width: calc(100% * 1.289);
      height: 100%;
      border-radius: 0 0 0 700px;
      background: #f3f0e3;
      position: absolute;
      z-index: -1;
      top: 50%;
      right: -252px;
      transform: translateY(-50%);

      @include mq(md, max) {
        width: calc(100% * 1.917);
        right: -127px;
        border-radius: 0px 0 0 370px;
      }
    }

    &__inner {
      display: block;
      width: 100%;
      max-width: 1024px;
      margin: 0 auto;

      @include mq(md, max) {
        padding: 0 20px;
      }
    }

    &__openhouse {
      display: block;
      width: 100%;
      margin: 40px 0 0;

      @include mq(md, max) {
        margin-top: 30px;
      }
    }

    &__openhouseInner {
      display: flex;
      align-items: center;
      column-gap: 40px;
      background-color: #fff;
      position: relative;
      padding: 12px;
      text-decoration: none;
      @include hoverOpacity;

      @include mq(md, max) {
        flex-direction: column;
        column-gap: 0;
        padding: 10px 10px 0;
      }

      &::after {
        content: "";
        display: block;
        color: #000;
        width: 15px;
        height: auto;
        aspect-ratio: 1/1;
        border: 3px solid #000;
        border-left: 0;
        border-bottom: 0;
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        right: 34px;
        transform: translateY(-50%) rotate(45deg);

        @include mq(md, max) {
          display: none;
        }
      }
    }

    &__openhouseImage {
      display: block;
      width: 390px;
      height: auto;
      aspect-ratio: 1/0.667;

      @include mq(md, max) {
        width: 100%;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__openhouseText {
      display: block;
      width: 488px;

      @include mq(md, max) {
        width: 100%;
        margin-top: 16px;
        padding: 0 16px;
      }
    }

    &__openhouseTextNote {
      display: block;
      font-size: 20px;
      line-height: calc(30 / 20);
      font-weight: 500;

      @include mq(md, max) {
        font-size: 13px;
      }
    }

    &__openhouseTextTitle {
      font-size: 34px;
      line-height: calc(51 / 34);
      font-weight: 500;
      margin: 9px 0 0;

      @include mq(md, max) {
        font-size: 20px;
        margin-top: 12px;
      }
    }

    &__openhouseTextInformation {
      font-size: 18px;
      line-height: calc(30 / 18);
      margin: 9px 0 0;

      @include mq(md, max) {
        font-size: 14px;
        margin-top: 12px;
      }
    }

    &__openhouseSpButton {
      display: none;

      @include mq(md, max) {
        display: block;
        width: calc(100% + 20px);
        font-size: 15px;
        line-height: calc(27 / 15);
        font-weight: 500;
        text-align: center;
        color: #fff;
        background-color: #787e83;
        padding: 6px 8px 9px;
        margin-top: 20px;
        position: relative;
      }

      &::after {
        @include mq(md, max) {
          content: "";
          display: block;
          color: #fff;
          width: 8px;
          height: auto;
          aspect-ratio: 1/1;
          border: 2px solid #fff;
          border-left: 0;
          border-bottom: 0;
          box-sizing: border-box;
          position: absolute;
          top: 50%;
          right: 14px;
          transform: translateY(-50%) rotate(45deg);
        }
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 50px 26px;
      width: 100%;
      margin: 80px 0 0;

      @include mq(md, max) {
        flex-direction: column;
        margin-top: 60px;
        gap: 30px 0;
        padding: 0 25px;
      }
    }

    &__listItem {
      display: block;
      width: calc((100% / 3) - (52px / 3));

      @include mq(md, max) {
        width: 100%;
      }

      &:nth-child(n + 4) {
        @include mq(md, max) {
          display: none;
        }
      }
    }

    &__listItemInner {
      display: block;
      position: relative;
      text-decoration: none;

      @include hover {
        .p-home-news {
          &__listItemImage {
            img {
              transform: scale(1.1);
            }
          }

          &__listItemText {
            text-decoration: underline;
          }
        }
      }
    }

    &__listItemLabel {
      display: inline-block;
      vertical-align: middle;
      font-size: 16px;
      line-height: calc(23 / 16);
      font-weight: 500;
      padding: 4px 12px 3px;
      background-color: #fff;

      @include mq(md, max) {
        font-size: 15px;
        padding: 2px 12px;
      }
    }

    &__listItemImage {
      display: block;
      width: 100%;
      height: auto;
      aspect-ratio: 1/0.667;
      border: 4px solid #fff;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1);
        transition: transform 0.5s ease;
      }
    }

    &__listItemText {
      display: block;
      width: 100%;

      @include mq(md, max) {
        padding: 0 3px;
      }
    }

    &__listItemTextTitle {
      font-size: 18px;
      line-height: calc(28 / 18);
      margin: 10px 0 0;

      @include mq(md, max) {
        font-size: 15px;
        margin-top: 6px;
      }
    }

    &__listItemTextDate {
      display: block;
      font-size: 16px;
      line-height: calc(19 / 16);
      margin: 5px 0 0;

      @include mq(md, max) {
        font-size: 13px;
        margin-top: 4px;
      }
    }

    &__button {
      display: block;
      width: 284px;
      margin: 56px auto 0;

      @include mq {
        transform: translateX(20px);
      }

      @include mq(md, max) {
        width: 280px;
        margin-top: 40px;
      }

      .c-button-01 {
        @include mq {
          padding-top: 14px;
          padding-bottom: 14px;
        }
      }
    }
  }

  // Future
  &-future {
    display: block;
    width: 100%;
    margin: 0;
    padding: 90px 0 100px;

    @include mq(md, max) {
      padding: 70px 0 80px;
    }

    &__inner {
      display: flex;
      justify-content: space-between;
      max-width: 1024px;
      margin: 0 auto;

      @include mq(md, max) {
        flex-direction: column;
        justify-content: center;
        padding: 0 20px;
      }
    }

    &__innerLeft {
      display: block;
      padding: 8px 0 0 80px;

      @include mq(md, max) {
        padding: 0 10px;
      }
    }

    &__innerRight {
      display: block;
      width: 640px;
      padding: 56px 0 0 0;

      @include mq(md, max) {
        width: 100%;
        padding: 0 10px;
      }
    }

    &__title {
      font-size: 37px;
      font-weight: 500;

      @include mq {
        line-height: calc(65 / 37);
        writing-mode: vertical-rl;
        letter-spacing: 0.16em;
      }

      @include mq(md, max) {
        font-size: 24px;
        line-height: calc(43 / 24);
      }
    }

    &__text {
      @include sectionInner;

      @include mq(md, max) {
        margin-top: 30px;
      }

      p {
        font-size: 18px;
        line-height: calc(40 / 18);
        margin: 18px 0 0;

        @include mq(md, max) {
          font-size: 15px;
          line-height: calc(27 / 15);
          margin-top: 15px;
        }
      }
    }

    &__button {
      display: block;
      width: 284px;
      margin: 50px 0 0;

      @include mq(md, max) {
        width: 280px;
        margin: 40px auto 0;
      }

      .c-button-01 {
        @include mq {
          padding-top: 14px;
          padding-bottom: 14px;
        }
      }
    }
  }

  // Works
  &-works {
    display: block;
    width: 100%;
    padding: 117px 0 124px;
    position: relative;
    overflow: hidden;

    @include mq(md, max) {
      padding: 80px 0 100px;
    }

    &::before {
      content: "";
      display: block;
      width: calc(100% * 1.289);
      height: 100%;
      border-radius: 0px 700px 700px 0px;
      background: #eff1f2;
      position: absolute;
      z-index: -1;
      top: 50%;
      right: -252px;
      transform: translateY(-50%);

      @include mq(md, max) {
        width: calc(100% * 1.917);
        right: -187px;
        border-radius: 0px 370px 370px 0px;
      }
    }

    &__inner {
      display: block;
      width: 100%;
      max-width: 1024px;
      margin: 0 auto;

      @include mq(md, max) {
        padding: 0 20px;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 109px 32px;
      list-style: none;
      margin: 60px 0 0;
      padding: 0;

      @include mq(md, max) {
        flex-direction: column;
        gap: 30px 0;
        margin-top: 30px;
        padding: 0 25px;
      }
    }

    &__listItem {
      display: block;
      width: calc((100% / 3) - (64px / 3));

      @include mq(md, max) {
        width: 100%;
      }

      &:nth-child(n + 4) {
        @include mq(md, max) {
          display: none;
        }
      }
    }

    &__listItemInner {
      display: block;
      width: 100%;
      @include hoverOpacity;
    }

    &__listItemImage {
      display: block;
      width: 100%;
      height: auto;
      aspect-ratio: 1/1;

      & > img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__listItemTitle {
      display: block;
      width: 100%;
      margin: 30px 0 0;
      font-size: 28px;
      font-weight: 500;

      @include mq(md, max) {
        font-size: 20px;
        margin-top: 14px;
      }
    }

    &__listItemText {
      display: block;
      width: 100%;
      margin: 22px 0 0;

      @include mq(md, max) {
        margin-top: 8px;
      }

      & > p {
        font-size: 18px;
        line-height: calc(29 / 18);
        margin: 0;

        @include mq(md, max) {
          font-size: 14px;
        }
      }
    }

    &__button {
      width: 300px;
      margin: 80px auto 0;

      @include mq(md, max) {
        width: 280px;
        margin-top: 40px;
      }

      .c-button-01 {
        @include mq {
          padding-top: 14px;
          padding-bottom: 14px;
        }
      }
    }
  }

  // Instagram
  &-instagram {
    display: block;
    width: 100%;
    padding: 130px 0 0;
    position: relative;

    @include mq(md, max) {
      padding: 70px 20px 0;
    }

    &::before {
      content: "";
      display: block;
      width: 448px;
      height: auto;
      aspect-ratio: 1/0.971;
      background-color: #f3f0e3;
      border-radius: 0 700px 700px 0;
      position: absolute;
      top: 270px;
      left: -145px;

      @include mq(md, max) {
        display: none;
      }
    }

    &::after {
      content: "";
      display: block;
      width: 728px;
      height: auto;
      aspect-ratio: 1/0.97;
      background-color: #f3f0e3;
      border-radius: 700px 0 0 700px;
      position: absolute;
      top: 344px;
      right: -346px;

      @include mq(md, max) {
        width: 255px;
        top: auto;
        right: -132px;
        bottom: -245px;
      }
    }

    &__inner {
      display: block;
      width: 100%;
      max-width: 1024px;
      margin: 0 auto;
      position: relative;
      z-index: 5;
    }

    &__feed {
      display: block;
      width: 100%;
      margin: 50px 0 0;

      @include mq(md, max) {
        margin-top: 30px;
      }

      // WordPress Instagram
      #sbi_images {
        & > div {
          height: auto;
          aspect-ratio: 1/1;

          .sbi_photo_wrap,
          .sbi_photo {
            height: 100% !important;
          }
        }
      }
    }

    &__button {
      @include mq(md, max) {
        width: 280px;
        margin: 40px auto 0;
      }
    }
  }

  // About
  &-about {
    display: block;
    width: 100%;
    padding: 177px 0 0;

    @include mq(md, max) {
      padding: 83px 0 0;
    }

    &__inner {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      width: 100%;
      max-width: 1024px;
      margin: 0 auto;

      @include mq(md, max) {
        flex-direction: column;
        gap: 60px;
        padding: 0 20px;
      }
    }

    &__item {
      display: block;
      width: calc(50% - 12px);

      @include mq(md, max) {
        width: 100%;
      }
    }

    &__title {
      font-size: 28px;
      line-height: calc(44 / 28);
      font-weight: 500;
      margin: 0;

      @include mq(md, max) {
        font-size: 20px;
      }
    }

    &__image {
      display: block;
      width: 100%;
      height: auto;
      aspect-ratio: 1/0.64;
      margin: 20px 0 0;

      @include mq(md, max) {
        margin-top: 16pxs;
      }

      & > img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__paragraph {
      font-size: 18px;
      line-height: calc(32 / 18);
      margin: 22px 0 0;
      padding-right: 10px;

      @include mq(md, max) {
        font-size: 14px;
        line-height: calc(25 / 14);
        margin-top: 10px;
        padding-right: 0;
      }
    }

    &__button {
      display: block;
      width: 228px;
      margin: 22px auto 0;

      @include mq(md, max) {
        width: 280px;
        margin-top: 30px;
      }
    }
  }
}
