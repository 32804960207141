@charset "UTF-8";

// ==================================================
//  Foundation Global
// ==================================================
@use "foundation/_global" as *;

// ==================================================
//  Flow
// ==================================================
.p-flow {
  overflow: hidden;

  // Schedule
  &-schedule {
    display: block;
    width: 100%;
    padding: 100px 0 120px;
    position: relative;
    overflow: hidden;

    @include mq(md, max) {
      padding: 70px 0 80px;
    }

    &::before {
      content: "";
      display: block;
      width: calc(100% * 1.289);
      height: 100%;
      border-radius: 0px 700px 700px 0px;
      background: #f3f0e3;
      position: absolute;
      z-index: -1;
      top: 50%;
      right: -252px;
      transform: translateY(-50%);

      @include mq(md, max) {
        width: calc(100% * 1.917);
        right: -187px;
        border-radius: 0px 370px 370px 0px;
      }
    }

    &__inner {
      @include sectionInner;
      max-width: 1024px;
      margin: 0 auto;

      @include mq(md, max) {
        padding: 0 20px;
      }
    }

    &__content {
      @include sectionInner;
      position: relative;
      margin-top: 76px;

      @include mq(md, max) {
        margin-top: 102px;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      width: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
      counter-reset: number;
      position: relative;
      z-index: 5;
    }

    &__listItem {
      display: block;
      font-size: 20px;
      line-height: calc(36 / 20);
      font-weight: 500;
      background-color: #fff;
      padding: 6px 74px;
      margin: 0;
      position: relative;

      @include mq(md, max) {
        font-size: 13px;
        padding: 5px 37px 3px;
      }

      &::before {
        counter-increment: number;
        content: counter(number);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 25px;
        line-height: 1;
        color: #fff;
        font-weight: 500;
        background-color: #86721f;

        @include mq(md, max) {
          width: 30px;
          font-size: 16px;
        }
      }
    }

    &__listItemNotice {
      display: inline;
      vertical-align: middle;
      font-size: 16px;
      line-height: calc(28 / 16);

      @include mq(md, max) {
        font-size: 11px;
      }
    }

    &__note {
      font-size: 14px;
      line-height: calc(26 / 14);
      margin: 21px 0 0;

      @include mq(md, max) {
        font-size: 12px;
        margin-top: 12px;
      }
    }

    &__image {
      display: block;
      width: 386px;
      position: absolute;
      top: -150px;
      right: 0;

      @include mq(md, max) {
        width: 210px;
        top: -81px;
      }
    }

    &__label {
      display: block;
      height: auto;
      position: absolute;
      z-index: 10;
      width: 150px;
      top: 9px;

      @include mq(md, max) {
        width: 37px;
        top: 8px;
      }

      // Modifier
      &--tochi {
        right: 201px;

        @include mq(md, max) {
          right: 53px;
        }
      }
      &--shikin {
        right: 41px;

        @include mq(md, max) {
          right: 10px;
        }
      }
    }
  }

  // Support
  &-support {
    display: block;
    width: 100%;
    padding: 120px 0 0;

    @include mq(md, max) {
      padding: 70px 0 0;
    }

    &__inner {
      @include sectionInner;
      max-width: 1024px;
      margin: 0 auto;

      @include mq(md, max) {
        padding: 0 20px;
      }
    }

    &__content {
      @include sectionInner;
      margin-top: 55px;

      @include mq(md, max) {
        margin-top: 40px;
      }
    }

    &__item {
      @include sectionInner;
      border: 4px solid #d9d9d9;
      background-color: #fff;
      padding: 46px 56px;
      margin-top: 20px;
      position: relative;

      @include mq(md, max) {
        border-width: 2px;
        padding: 24px 22px;
      }

      &:not(:last-of-type) {
        &::before {
          content: "";
          display: block;
          width: 7px;
          height: 22px;
          background-color: #ab9e65;
          position: absolute;
          z-index: -1;
          bottom: -24px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    &__itemTitle {
      font-size: 28px;
      line-height: calc(44 / 28);
      margin: 28px 0 0;
      font-weight: 500;
      color: #86721f;

      @include mq(md, max) {
        font-size: 18px;
        line-height: calc(26 / 18);
        margin-top: 18px;
      }
    }

    &__itemInner {
      display: flex;
      align-items: flex-start;

      @include mq(md, max) {
        flex-direction: column-reverse;
      }

      // Modifier
      &--image {
        .p-flow-support {
          &__itemText {
            @include mq {
              width: calc(100% - 392px);
              padding-right: 25px;
            }
          }
        }
      }
    }

    &__itemText {
      @include sectionInner;
      margin: 18px 0 0;

      @include mq(md, max) {
        margin-top: 10px;
      }
    }

    &__itemTextParagraph {
      font-size: 18px;
      line-height: calc(36 / 18);
      margin: 16px 0 0;

      @include mq(md, max) {
        font-size: 14px;
        line-height: calc(23 / 14);
        margin-top: 10px;
      }
    }

    &__itemImage {
      display: block;
      width: 392px;

      @include mq(md, max) {
        width: 100%;
        margin-top: 10px;
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }
}
