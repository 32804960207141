@charset "UTF-8";

// ==================================================
//  Foundation Global
// ==================================================
@use "foundation/_global" as *;

// ==================================================
//  Button
// ==================================================
.c-button {
  // Cta Fixed
  &-ctaFixed {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    position: fixed;
    z-index: 75;
    right: 20px;
    bottom: 40px;
    transform: translateX(calc(100% + 20px));
    transition: transform 0.4s;

    @include mq(md, max) {
      row-gap: 4px;
      right: 6px;
      bottom: 12px;
      transform: translateX(calc(100% + 6px));
    }

    &.is-show {
      transform: translateX(0);
    }

    &__button {
      display: block;
      width: 120px;
      @include hoverOpacity;

      @include mq(md, max) {
        width: 80px;
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }

  // 01
  &-01 {
    display: block;
    width: 100%;
    font-size: 18px;
    line-height: calc(32 / 18);
    padding: 8px 12px 9px 8px;
    font-weight: 500;
    border: 2px solid #000;
    background-color: #fff;
    position: relative;
    text-align: center;
    transition: background-color $c-transition, color $c-transition;

    @include mq(md, max) {
      font-size: 15px;
      padding: 11px;
    }

    &::after {
      content: "";
      display: block;
      width: 8px;
      height: auto;
      aspect-ratio: 1/1;
      border: 2px solid #000;
      border-left: 0;
      border-bottom: 0;
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      right: 19px;
      transform: translateY(-50%) rotate(45deg);
      transition: border-color $c-transition;
    }

    @include hover {
      color: #fff;
      background-color: #000;

      &::after {
        border-color: #fff;
      }
    }
  }

  // 02
  &-02 {
    display: block;
    width: 100%;
    font-size: 18px;
    line-height: calc(32 / 18);
    padding: 13px 12px 14px 8px;
    font-weight: 500;
    color: #fff;
    border: 2px solid #000;
    background-color: #000;
    position: relative;
    text-align: center;
    transition: background-color $c-transition, color $c-transition;

    @include mq(md, max) {
      font-size: 15px;
      padding: 11px;
    }

    &::after {
      content: "";
      display: block;
      width: 8px;
      height: auto;
      aspect-ratio: 1/1;
      border: 2px solid #fff;
      border-left: 0;
      border-bottom: 0;
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      right: 19px;
      transform: translateY(-50%) rotate(45deg);
      transition: border-color $c-transition;
    }

    @include hover {
      color: #000;
      background-color: #fff;

      &::after {
        border-color: #000;
      }
    }
  }
}
