@charset "UTF-8";

// ==================================================
//  Foundation Global
// ==================================================
@use "foundation/_global" as *;

// ==================================================
//  Design
// ==================================================
.p-design {
  overflow: hidden;

  // Whats
  &-whats {
    display: block;
    width: 100%;
    padding: 100px 0 120px;
    position: relative;
    overflow: hidden;

    @include mq(md, max) {
      padding: 70px 0 80px;
    }

    &::before {
      content: "";
      display: block;
      width: calc(100% * 1.289);
      height: 100%;
      border-radius: 0px 700px 700px 0px;
      background: #f3f0e3;
      position: absolute;
      z-index: -1;
      top: 50%;
      right: -252px;
      transform: translateY(-50%);

      @include mq(md, max) {
        width: calc(100% * 1.917);
        right: -187px;
        border-radius: 0px 370px 370px 0px;
      }
    }

    &__inner {
      @include sectionInner;
      max-width: 1024px;
      margin: 0 auto;

      @include mq(md, max) {
        padding: 0 20px;
      }
    }

    &__item {
      display: flex;
      align-items: flex-start;
      margin-top: 98px;

      @include mq(md, max) {
        flex-direction: column;
        margin-top: 50px;
      }
    }

    &__itemText {
      display: block;
      width: calc(100% - 355px);
      padding-right: 60px;

      @include mq(md, max) {
        width: 100%;
        padding-right: 0;
      }
    }

    &__itemTextTitle {
      padding-bottom: 22px;

      @include mq(md, max) {
        padding-bottom: 0;
      }
    }

    &__itemTextParagraph {
      font-size: 18px;
      line-height: calc(36 / 18);
      margin: 18px 0 0;

      @include mq(md, max) {
        font-size: 15px;
        line-height: calc(27 / 15);
        margin-top: 20px;
      }
    }

    &__itemImage {
      display: block;
      width: 355px;

      @include mq(md, max) {
        width: 197px;
        margin: 20px auto 0;
      }

      & > img {
        display: block;
        width: 100%;
      }
    }
  }

  // Follow
  &-follow {
    display: block;
    width: 100%;
    padding: 120px 0 0;

    @include mq(md, max) {
      padding: 70px 0 0;
    }

    &__inner {
      display: block;
      width: 100%;
      max-width: 1024px;
      margin: 0 auto;

      @include mq(md, max) {
        padding: 0 20px;
      }
    }

    &__content {
      @include sectionInner;
      margin-top: 40px;

      @include mq(md, max) {
        margin-top: 30px;
      }
    }

    &__paragraph {
      font-size: 18px;
      line-height: calc(36 / 18);
      margin: 20px 0 0;

      @include mq(md, max) {
        font-size: 15px;
        line-height: calc(27 / 15);
      }
    }

    &__button {
      display: block;
      width: 400px;
      margin: 90px auto 0;

      @include mq(md, max) {
        width: 300px;
        margin-top: 40px;
      }
    }
  }
}
