@charset "UTF-8";

// ==================================================
//  Foundation Global
// ==================================================
@use "foundation/_global" as *;

// ==================================================
//  Breadcrumbs
// ==================================================
.c-breadcrumbs {
  display: block;
  width: calc(100% - 40px);
  max-width: 1024px;
  margin: 0 auto;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  &__inner {
    display: block;
    margin-left: auto;
    font-size: 16px;
    line-height: 1;

    @include mq(md, max) {
      font-size: 12px;
    }

    * {
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
      white-space: nowrap;
    }

    a,
    span {
      display: inline-block;
    }

    a {
      color: inherit;
      text-decoration: none;
      @include hoverOpacity;

      &::after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 9px;
        height: auto;
        aspect-ratio: 1/1;
        border: 1px solid #000;
        border-left: 0;
        border-bottom: 0;
        box-sizing: border-box;
        transform: translateY(-1px) rotate(45deg);
        transition: border-color $c-transition;
        margin: 0 10px;
      }
    }
  }
}
