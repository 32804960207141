@charset "UTF-8";

// ==================================================
//  Foundation Global
// ==================================================
@use "foundation/_global" as *;

// ==================================================
//  Header
// ==================================================
.l-header {
  // 既存（#header）のカスタマイズ
  #logo,
  .contact,
  #menu,
  #button-menu {
    display: none !important;
  }

  &:has(+ .p-home) {
    .l-header {
      &__inner {
        @include mq {
          border: none;
        }
      }
    }
  }

  &__inner {
    @include mq {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 19px 16px 21px;
      width: 100%;
      min-width: 1077px;
      position: fixed;
      top: 0;
      left: 0;
      border-bottom: 1px solid #d9d9d9;
      background-color: #fff;
      // transition: transform 0.4s, opacity 0.4s;
      // transform: translateY(-15px);
      // opacity: 0;
    }

    &.is-show {
      @include mq {
        // background-color: #fffefb;
        // transform: translateY(0);
        // opacity: 1;
      }
    }
  }

  &__navigation {
    @include mq(md, max) {
      display: block;
      width: 100%;
      height: 100%;
      padding: 70px 20px 0;
      overflow: auto;
      position: fixed;
      z-index: 90;
      top: 0;
      left: 0;
      background-color: #fff;
      opacity: 0;
      visibility: hidden;
      transition: opacity $c-transition, visibility $c-transition;
    }

    &.is-open {
      @include mq(md, max) {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__logo {
    @include mq {
      display: block;
      width: 130px;
      height: auto;
    }

    @include mq(md, max) {
      width: 95px;
      position: fixed;
      top: 12px;
      left: 20px;
      z-index: 95;
    }

    a,
    img {
      @include mq {
        display: block;
        width: 100%;
      }
    }
  }

  &__menu {
    display: block;
    padding-right: 24px;

    @include mq(md, max) {
      padding: 0;
    }
  }

  &__menuList {
    display: flex;
    column-gap: rem(30);
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;

    @include mq(md, max) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-top: 1px solid #d9d9d9;
    }
  }

  &__menuListItem {
    font-size: 15px;
    line-height: calc(27 / 15);
    font-weight: 500;

    @include mq(md, max) {
      display: block;
      width: 100%;
      font-size: 14px;
      border-bottom: 1px solid #d9d9d9;
    }
  }

  &__menuListItemInner {
    text-decoration: none;

    @include mq(md, max) {
      display: block;
      padding: 8px 10px 10px;
      position: relative;
    }

    @include hoverOpacity;

    &::after {
      @include mq(md, max) {
        content: "";
        display: block;
        color: #000;
        width: 8px;
        height: auto;
        aspect-ratio: 1/1;
        border: 2px solid #000;
        border-left: 0;
        border-bottom: 0;
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        right: 9px;
        transform: translateY(-50%) rotate(45deg);
      }
    }
  }

  &__social {
    display: none;

    @include mq(md, max) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      column-gap: 14px;
    }

    .icon {
      @include mq(md, max) {
        font-size: 30px;
      }
    }
  }

  &__linksSocialItem {
    display: none;

    @include mq(md, max) {
      display: block;
    }
  }

  &__menuButton {
    display: none;

    @include mq(md, max) {
      display: block;
      width: 30px;
      height: 26px;
      position: fixed;
      z-index: 90;
      top: 21px;
      right: 20px;
      border-radius: 0;
      background: none;
    }

    &.is-open {
      img {
        &:first-of-type {
          @include mq(md, max) {
            display: none;
          }
        }

        &:last-of-type {
          @include mq(md, max) {
            display: block;
          }
        }
      }
    }

    img {
      &:first-of-type {
        @include mq(md, max) {
          display: block;
        }
      }

      &:last-of-type {
        @include mq(md, max) {
          display: none;
        }
      }
    }
  }
}
